import React, { useRef, useEffect } from "react"
import resl from "resl"
import REGL from "regl"
import { useSpring } from "react-spring"

import * as simulation from "./water/simulation"
import gradientSrc from "../images/gradient.png"
import noiseSrc from "../images/noise.png"
import blurFrag from "./water/blur_frag.glsl"
import surfaceFrag from "./water/surface_frag.glsl"
import vert from "./water/vert.glsl"
import { api as viewportApi } from "../hooks/useViewportState"

const QUAD = {
  positions: [
    [-1, -1, 0],
    [1, -1, 0],
    [1, 1, 0],
    [-1, 1, 0],
  ],
  cells: [
    [0, 1, 2],
    [2, 3, 0],
  ],
}

const WaterComponent = props => {
  let container = useRef()

  let [mouseMoving, setMouseMoving] = useSpring(() => ({
    value: 0,
  }))
  let viewport = viewportApi.getState()
  let [mouseSpring, setMouse] = useSpring(() => ({
    xy: [viewport.mouseX, viewport.mouseY],
    config: {
      mass: 1,
      tension: 800,
      friction: 30,
    },
    onStart: () => {
      setMouseMoving({ value: 1 })
    },
    onRest: () => {
      setMouseMoving({ value: 0 })
    },
  }))

  let { stage } = useSpring({
    stage: props.stage,
    config: {
      mass: 1,
      tension: 200,
      friction: 50,
    },
  })

  useEffect(() => {
    let regl = REGL(container.current)

    let canvasBounds = regl._gl.canvas.getBoundingClientRect()
    let unsubBounds = viewportApi.subscribe(
      bounds => {
        canvasBounds = bounds
      },
      _state => regl._gl.canvas.getBoundingClientRect()
    )

    let [WIDTH, HEIGHT] = simulation.getDimensions(canvasBounds)
    let waterSim = simulation.make({ WIDTH, HEIGHT })

    let unsubMouse = viewportApi.subscribe(
      xy => {
        setMouse({ xy })
        waterSim.updateMouse(
          ~~(((xy[0] - canvasBounds.left) / canvasBounds.width) * WIDTH),
          ~~(((xy[1] - canvasBounds.top) / canvasBounds.height) * HEIGHT)
        )
      },
      state => [state.mouseX, state.mouseY]
    )

    let fbo = regl.framebuffer({
      color: regl.texture({
        width: 1,
        height: 1,
        wrap: "clamp",
      }),
    })

    let drawToFBO = regl({ framebuffer: fbo })

    const drawSurface = regl({
      frag: surfaceFrag,
      vert,
      attributes: {
        position: QUAD.positions,
      },
      elements: QUAD.cells,
      uniforms: {
        time: ({ time }) => time,
        canvas: regl.prop("canvas"),
        mouse: regl.prop("mouse"),
        mouseFactor: regl.prop("mouseFactor"),
        water: regl.prop("water"),
        waterSize: regl.prop("waterSize"),
        gradient: regl.prop("gradient"),
        devicePixelRatio,
        stage: () => stage.getValue(),
      },
    })

    const blur = regl({
      frag: blurFrag,
      vert,
      attributes: {
        position: QUAD.positions,
      },
      elements: QUAD.cells,
      uniforms: {
        canvas: regl.prop("canvas"),
        mouse: regl.prop("mouse"),
        noise: regl.prop("noise"),
        fbo: () => fbo,
        devicePixelRatio,
        time: ({ time }) => time,
        stage: () => stage.getValue(),
      },
    })

    // graphics.current.regl = regl
    let drawFrame = props => {
      let gl = regl._gl
      let canvas = [gl.canvas.width, gl.canvas.height]
      let mouse = mouseSpring.xy.getValue()
      fbo.resize(gl.canvas.width, gl.canvas.height)
      regl.clear({
        color: [0, 0, 0, 255],
        depth: true,
      })
      drawToFBO({}, () => {
        regl.clear({
          color: [0, 0, 0, 255],
          depth: true,
        })
        drawSurface({
          canvas,
          mouse,
          mouseFactor: mouseMoving.value.getValue(),
          water: props.water,
          waterSize: [waterSim.WIDTH, waterSim.HEIGHT],
          noise: props.noise,
          gradient: props.gradient,
        })
      })
      blur({
        canvas,
        mouse,
        noise: props.noise,
      })
    }
    resl({
      manifest: {
        gradient: {
          type: "image",
          src: gradientSrc,
          parser: data => regl.texture({ data, min: "linear", mag: "linear" }),
        },
        noise: {
          type: "image",
          src: noiseSrc,
          parser: data =>
            regl.texture({
              data,
              min: "linear",
              mag: "linear",
              wrapS: "repeat",
              wrapT: "repeat",
            }),
        },
      },
      onDone: ({ gradient, noise }) => {
        let waterTexture = regl.texture({
          width: waterSim.WIDTH,
          height: waterSim.HEIGHT,
          data: waterSim.getData(),
          min: "linear",
          mag: "linear",
        })
        regl.frame(() => {
          waterTexture.subimage(waterSim.getData())
          drawFrame({
            gradient,
            noise,
            water: waterTexture,
          })
        })
      },
    })

    return () => {
      unsubBounds()
      unsubMouse()
      try {
        regl.destroy()
      } catch (e) {}
      try {
        waterSim.destroy()
      } catch (e) {}
    }
  }, [])

  return <div ref={container} className={props.className} />
}

export default WaterComponent
